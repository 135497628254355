$red: rgb(204, 0, 0);

html, body {
  margin: 0;
  padding: 0;
  @font-face {
    font-family: 'Persona Regular';
    src: url('./../public/fonts/PersonifiedUXRegular.ttf') format('truetype');
  }
  font-family: 'Persona Regular', sans-serif;
  color: white;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-repeat: no-repeat, repeat;
  background-image: linear-gradient(to bottom right, rgba(255,0,0,0), rgba(255,0,0,1)),
    url('./logo.svg');
  background-size: cover, 10%;
  @media screen and (max-width: 1000px) {
    background-size: cover, 50%;
  }
}

#root {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

button {
  font-family: 'Persona Regular', sans-serif;
}

.black-container {
  width: 100%;
  height: 90vh;
  max-width: 700px;
  padding: 10px;
  margin: 0 20px;
  background-color: black;
  transform: skewX(-2deg);
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1000px) {
    max-width: 90vw;
  }
}

.white-container {
  padding: 10px;
  background-color: black;
  border: 10px solid white;
  transform: skewX(1deg);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.red-container {
  padding: 10px;
  background-color: $red;
  height: 100%;
}

.file-input {
  display: none;
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  h1, h4 {
    margin: 0;
  }
}

.sub-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
}

.p-button {
  cursor: pointer;
  background-color: black;
  color: white;
  padding: 10px;
  border: 5px solid white;
  transform: skewX(5deg);
  &:hover {
    transform: rotate(5deg);
  }
}

.message-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  gap: 10px;

  .message {
    display: inline;
    padding: 10px;
    border: 5px solid white;
    max-width: fit-content;
    min-width: 16px;
  }

  .client-message {
    background-color: black;
    color: white;
    transform: skewX(10deg);
    align-self: flex-start;
  }

  .server-message {
    background-color: white;
    color: black;
    border-color: black;
    transform: skewX(-10deg);
    align-self: flex-end;
  }
}

